export const validatePermission = (
  userPermissions: any,
  permission: string
) => {
  return userPermissions.find((p: any) => p.name === permission) ? true : false;
};

export const isAdminUser = () => {
  const user = JSON.parse(localStorage.getItem("aedas_user") ?? "{}")?.user;
  return (
    String(user?.["email"]).toLowerCase() === process.env.REACT_APP_ADMIN_USER
  );
};
