import { Card } from "@nextui-org/react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

const ClientInfo = (props: any) => {
  const { client } = props;

  return (
    <div
      style={{
        display: "flex",
        padding: "1rem",
        gap: "1rem",
        alignItems: "center",
        width: "50%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Card
          style={{
            height: "80px",
            width: "80px",
            background: "var(--lis-ligthblue)",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "25px",
            color: "var(--secondary-color)",
          }}
        >
          {client?.displayName
            ?.split(" ")
            .map((word: any) => word?.[0]?.toUpperCase())}
        </Card>
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <div>
          <h1
            style={{
              textAlign: "start",
              fontFamily: "lis-title",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {client?.displayName}
          </h1>
          <h1
            style={{
              textAlign: "start",
              fontFamily: "lis-body",
              fontSize: "15px",
              display: "flex",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <AiOutlineMail></AiOutlineMail>
            </div>

            {client?.email}
          </h1>
          <h1
            style={{
              textAlign: "start",
              fontFamily: "lis-body",
              fontSize: "15px",
              display: "flex",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <AiOutlinePhone size={15}></AiOutlinePhone>
            </div>

            {client?.phone ?? "-"}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
