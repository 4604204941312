import { isAdminUser } from "./permissionsUtils";

/**
 * @description Capitalizar una frase o string, por ejemplo:
 * "mi frase" --> "Mi frase"
 * "MI FRASE" --> "Mi frase"
 *
 * @param {string} myString String a capitalizar
 * @returns
 */
export const capitalizeString = (myString: any) => {
  if (myString?.length === 0) {
    return "";
  }

  const firstLetter = myString?.charAt(0)?.toUpperCase();
  const restOfString = myString?.slice(1)?.toLowerCase();

  return firstLetter + restOfString;
};

/**
 * @description Devuelve un precio a formato número con euros.
 *
 * @param {number} price Número a formatear
 * @returns
 */
export const priceFormatter = (price: any) => {
  if (!price) return "0€";

  const decimalSeparator = ",";
  const decimalDigits = 2;

  const priceString = Number(price).toFixed(decimalDigits);
  const [integerPart, decimalPart] = priceString.split(".");
  const integerFormatted = new Intl.NumberFormat().format(Number(integerPart));

  let priceFormatted = integerFormatted;
  if (decimalPart && decimalPart !== "00") {
    priceFormatted += decimalSeparator + decimalPart;
  }

  return priceFormatted + "€";
};

export const tipsMessages = {
  show: {
    elQueAbandonaElNido: {
      tierra: "msg de tierra",
      fuego: "msg de fuego",
      agua: "msg deagua",
      aire: "msg de aire",
    },
  },
};
/**
 * @description Devuelve el usuario dependiendo si esta en PRO o en otro env.
 *
 * @returns
 */
export const getUser = () => {
  if (process.env.REACT_APP_IS_PRO === "false" || isAdminUser()) {
    return JSON.parse(localStorage.getItem("seller_AEDAS_demo") ?? "{}");
  }

  return JSON.parse(localStorage.getItem("aedas_user") ?? "{}")?.user;
};

/**
 * @description Rendirije a otra web externa
 *
 * @returns null
 */

export const goToURL = (url: string) => {
  window.location.href = url;
  return null;
};

export const getPlainName = (name: string) => {
  // Eliminar la palabra "plano" del texto
  if (name.toLowerCase().includes("otro")) {
    return "otros";
  }
  name = name.replace("Plano_", "");

  // Reemplazar los guiones bajos "_" por espacios
  name = name.replace(/_/g, " ");

  return name;
};

/**
 * @description Pinta el tipo de casa según el string obtenido desde el endpoint.
 * @param {string} typeOfHouse Puede ser "vi" o "ch".
 * @returns {string} Tipo de casa formateado, a saber: "Casa" o "Chalet".
 */
export const writeCorrectNameToHouseType = (typeOfHouse: any) => {
  typeOfHouse = typeOfHouse.toLowerCase();

  if (typeOfHouse.includes("vi")) {
    return "Casa";
  } else if (typeOfHouse.includes("ch")) {
    return "Chalet";
  } else {
    return " - ";
  }
};

/**
 * @description Función para obtener el nombre de un string en formato "número - nombre".
 * @param {string} promotionName "175 - Freya".
 * @returns {string} Devuelve el string formateado: Freya.
 */
export const writeCorrectNameOfPromotion = (promotionName: any) => {
  // Dividir el string en dos partes separadas por el guión
  const parts = promotionName?.toString().split(" - ");

  // Verificar si el string cumple con el formato esperado
  if (parts?.length !== 2) {
    return promotionName ? promotionName?.toString() : "Sin promoción";
  }

  // Devolver la segunda parte que contiene el nombre
  return parts[1]?.toString();
};

/**
 * Recibe la característica de una promoción, la convierte a minúscula, elimina espacios guiones y barras. Además los acentos, los sustituye por la letra correspondiente.
 * Se usa para limpiar valores de "salesforce" y remarcar las características de una promoción que el vendedor ha marcado en Calidades/Equipamiento de la vivienda.
 *
 * Ver también FEATURES_LIST -> en Utils/feautresList --> Ahí se matchea el valor de salesforce a algo "legible"
 * @param str
 * @returns
 */
export const cleanFeatureName = (str: any) => {
  // Convertir a minúsculas
  str = str.toLowerCase();

  // Eliminar espacios
  str = str.replace(/\s/g, "");

  // Eliminar guiones y barras altas
  str = str.replace(/[-_\/]/g, "");

  // Sustituir acentos por letras sin acento
  //@ts-ignore
  const acentos: any = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ñ: "n",
  };

  // Reemplazar caracteres especiales y acentuados
  //@ts-ignore
  str = str.replace(/[áéíóúñ]/g, (match: any) => acentos[match] || match);

  // Eliminar caracteres especiales
  str = str.replace(/[^\w\s]/g, "");

  return str;
};

/**
 * Obtiene el nombre "bonito" de una característica, digamos que es la función "inversa" de "cleanFeatureName", en este mismo fichero.
 * @param clave
 * @returns
 */
export const getCleanFeatureName = (str: any) => {
  const options: any = {
    parkingcomunitario: "Parking comunitario",
    tenis: "Tenis",
    jardin: "Jardín",
    piscina: "Piscina",
    pistapolideportiva: "Pista polideportiva",
    gimnasio: "Gimnasio",
    salacomun: "Sala Común",
    padel: "Pádel",
    piscinainfantil: "Piscina infantil",
    zonainfantil: "Zona infantil",
    conserjeria: "Conserjería",
    videoportero: "Videoportero",
    ascensor: "Ascensor",
    trastero: "Trastero",
    salabicicletas: "Sala bicicletas",
    sueloradiante: "Sueldo radiante",
    sueloradianteyrefrescante: "Sueldo radiante y refrescante",
    aerotermia: "Aerotermia",
    geotermia: "Geotermia",
  };

  return options[str] || "";
};

// Array que contiene aquellos arquetipos que pueden tener hijos, si el arquetipo es distinto de alguno de estos, no podrán seleccionar el número de hijos.
// Recordar que los archetipos se cargan desde base de datos.
export const archetypeCanHaveChildren = {
  3: "Fátima y Cesar: La familia en crecimiento",
  4: "Fausto y Carmen: Un futuro para mis hijos",
  5: "Nuria y Esteban: Un nuevo episodio",
  8: "Diane & Chris: Un descanso cómodo",
  10: "Nieves y Hugo: Un piso para nuestra hija",
};
